import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { MessageService } from 'lib';
import { ModalOverlayRef } from 'lib';
import { InvestorService } from '../../services/investor.service';
import { SET_MOBILE_NUMBER_MUTATION, SET_NAME_MUTATION } from '../../helpers/api';
import { finalize } from 'rxjs/operators';
import { Mutation } from 'lib/src/types/schema';
import { SmsAuthService } from '../../services/sms-auth.service';

@Component({
  selector: 'kr-set-mobile-number',
  templateUrl: './set-mobile-number.component.html',
  styleUrls: ['./set-mobile-number.component.scss']
})
export class SetMobileNumberComponent implements OnInit {
  form = new FormGroup(
    {
      mobileNumber: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(/^\d{11}$/)
        ]
      }),
      // authCode: new FormControl('', {
      //   validators: [
      //     Validators.required,
      //   ]
      // }),
    }
  );
  loading = false;
  mobileNumber = this.form.controls.mobileNumber;
  authCode = this.form.controls.authCode;

  constructor(
    private overlayRef: ModalOverlayRef,
    public investorSer: InvestorService,
    private apollo: Apollo,
    private messageSer: MessageService,
    public smsAuthSer: SmsAuthService
  ) { }

  ngOnInit(): void {
  }

  submit() {
    if (this.loading === true) return;
    this.loading = true;
    this.apollo.mutate<Mutation>({
      mutation: SET_MOBILE_NUMBER_MUTATION,
      variables: { input: this.form.value }
    }).pipe(finalize(() => this.loading = false))
    .subscribe(resp => {
      if (resp.data?.investor?.setMobileNumber?.success) {
        this.messageSer.notify('设置成功');
        this.investorSer.investorInfoQuery.refetch();
        this.close();
      }
    })
  }

  close() {
    this.overlayRef.close();
  }
}
