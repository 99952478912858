import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { MessageService, ModalOverlayRef } from 'lib';
import { Mutation } from 'lib/src/types/schema';
import { finalize } from 'rxjs/operators';
import { SET_SECURITY_PASSWORD_MUTATION } from '../../helpers/api';
import { valueMatch } from '../../helpers/custom-validator';
import { InvestorService } from '../../services/investor.service';
import { SmsAuthService } from '../../services/sms-auth.service';

@Component({
  selector: 'kr-set-security-password',
  templateUrl: './set-security-password.component.html',
  styleUrls: ['./set-security-password.component.scss']
})
export class SetSecurityPasswordComponent implements OnInit {
  loading = false;
  form = new FormGroup(
    {
      oldPassword: new FormControl('',{
        updateOn: 'blur',
        validators: [
          ...(this.investorSer.hasSecurityPassword.value === true ? [Validators.required] : []),
          // Validators.pattern(`^(?=.*[a-zA-Z])(?=.*\\d)[a-zA-Z][a-zA-Z\\d]{8,16}$`)
        ],
      }),
      newPassword: new FormControl('', {
        updateOn: 'blur',
        validators: [
          Validators.required,
        ]
      }),
      newPasswordConfirmation: new FormControl('', {
        validators: [
          Validators.required
        ]
      }),
    }, 
    valueMatch('newPassword', 'newPasswordConfirmation')
  );
  oldPassword = this.form.controls.oldPassword;
  newPassword = this.form.controls.newPassword;
  newPasswordConfirmation = this.form.controls.newPasswordConfirmation;
  // authCode = this.form.controls.authCode;

  constructor(
    private overlayRef: ModalOverlayRef,
    public investorSer: InvestorService,
    private apollo: Apollo,
    private messageSer: MessageService,
    public smsAuthSer: SmsAuthService
  ) { }

  ngOnInit(): void {
  }

  submit() {
    if (this.loading === true) return;
    this.loading = true;
    this.apollo.mutate<Mutation>({
      mutation: SET_SECURITY_PASSWORD_MUTATION,
      variables: { input: this.form.value }
    }).pipe(finalize(() => this.loading = false))
      .subscribe(resp => {
        if (resp.data?.investor?.setSecurityPassword?.success) {
          this.messageSer.notify('密码设置成功');
          // 更新 hasTransactionPassword 欄位
          this.investorSer.hasSecurityPassword.next(true);
          this.close();
        }
      })
  }


  close() {
    this.overlayRef.close();
  }

}
