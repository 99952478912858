import {
  AbstractControl,
  FormControl, ValidatorFn
} from '@angular/forms';

export const valueMatch = (field1Name: string, field2Name: string): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const field1 = control.get(field1Name);
    const field2 = control.get(field2Name);

    return field1 && field1.dirty && field1.touched &&
      field2 && (field2.dirty || field2.touched) &&
      field1.value !== field2.value ? { valueMismatch: true } : null;
  };
}