import { Component, Input, OnInit } from '@angular/core';
import { ModalOverlayRef } from 'lib';

@Component({
  selector: 'kr-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() textAsHtml: boolean = false;
  @Input() btnCloseTitle: string = '';
  @Input() onClose: () => any = () => { };
  constructor(
    private overlayRef: ModalOverlayRef
  ) { }

  ngOnInit() {
  }

  close() {
    this.onClose();
    this.overlayRef.close();
  }

}
